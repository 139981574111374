<template>
	<div style="width: 100vw; height: 100vh;" class="flex vertical">
		<h3 class="mxauto" style="margin-top: auto">
			This page is only accessible by admins
		</h3>
		<p style="margin-bottom: auto;" class="mbauto mxauto">Go back <a href="/">home</a></p>
	</div>
</template>

<script>
export default {
	name: 'NoEntry'
}
</script>